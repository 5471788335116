<template>
  <div>
    <router-view></router-view>
    <v-row class="m-4">
      <v-col cols="12" md="12" class="text-center">
        <h4>
          You can't load this page on this device right now because its
          performance isn't sufficient.
          <br />
          Please open this page on your PC. I apologize for any
          inconvenience...
        </h4>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "MemoryExceed"
};
</script>

<style></style>
